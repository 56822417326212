import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { types as sdkTypes } from '../../../util/sdkLoader';

import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl';

import classNames from 'classnames';

import css from './StoreMinimumOrderProgressBar.module.css';
import { propTypes } from '../../../util/types';
import { formatMoney } from '../../../util/currency';

const { Money } = sdkTypes;

const IconTickCircle = ({ disabled }) => {
  const fillColor = disabled ? '#e7e7e7' : '#009345';

  return (
    <svg
      className={css.tickCircle}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path
          d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
          fill={fillColor}
        ></path>{' '}
      </g>
    </svg>
  );
};

const StoreMinimumOrderProgressBarComponent = props => {
  const { className, rootClassName, currentSeller, cartTotalPrice, intl } = props;

  const storeMinimumOrderAmount =
    currentSeller.attributes.profile.publicData?.storeMinimumOrderAmount;
  
  if (
    !storeMinimumOrderAmount?.amount ||
    !storeMinimumOrderAmount?.currency ||
    !cartTotalPrice?.amount ||
    !cartTotalPrice?.currency
  ) {
    return null;
  }

  const formattedStoreMinimunPrice = formatMoney(
    intl,
    new Money(storeMinimumOrderAmount.amount, storeMinimumOrderAmount.currency)
  );

  const isExceedMinimumStoreOrder = cartTotalPrice.amount >= storeMinimumOrderAmount.amount;
  const remainingAmount = isExceedMinimumStoreOrder
    ? 0
    : storeMinimumOrderAmount.amount - cartTotalPrice.amount;
  const formattedRemainingAmount = formatMoney(
    intl,
    new Money(remainingAmount, cartTotalPrice.currency)
  );
  const percentage = (cartTotalPrice.amount / storeMinimumOrderAmount.amount) * 100;

  return (
    <div className={classNames(rootClassName || css.root, className)}>
      {isExceedMinimumStoreOrder ? (
        <FormattedMessage
          id="CartPage.exceedMinimumStoreOrder"
          values={{ minimumAmount: formattedStoreMinimunPrice }}
        />
      ) : (
        <FormattedMessage
          id="CartPage.minimumStoreOrderAmountTogo"
          values={{
            remainingAmount: formattedRemainingAmount,
            minimumAmount: formattedStoreMinimunPrice,
          }}
        />
      )}
      <div className={css.progressBarContainer}>
        <div className={css.progressBarWrapper}>
          <div className={css.progressBar}>
            <span style={{ width: `${percentage}%` }} className={css.progressBarFill}></span>
          </div>
        </div>
        <IconTickCircle disabled={!isExceedMinimumStoreOrder} />
      </div>
    </div>
  );
};
StoreMinimumOrderProgressBarComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

StoreMinimumOrderProgressBarComponent.propTypes = {
  currentSeller: propTypes.seller.isRequired,
  cartTotalPrice: propTypes.money.isRequired,
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

const StoreMinimumOrderProgressBar = compose(injectIntl)(StoreMinimumOrderProgressBarComponent);
StoreMinimumOrderProgressBar.displayName = 'StoreMinimumOrderProgressBar';

export default StoreMinimumOrderProgressBar;
