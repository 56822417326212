import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const ASSET_NAME = 'landing-page';

export const QUERY_LISTINGS_REQUEST = 'app/LandingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/LandingPage/QUERY_LISTINGS_ERROR';

export const QUERY_FEATURED_LISTINGS_REQUEST = 'app/LandingPage/QUERY_FEATURED_LISTINGS_REQUEST';
export const QUERY_FEATURED_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_FEATURED_LISTINGS_SUCCESS';
export const QUERY_FEATURED_LISTINGS_ERROR = 'app/LandingPage/QUERY_FEATURED_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryListingIds: [],
  queryListingsInProgress: false,
  queryListingsError: null,

  queryFeaturedListingIds: [],
  queryFeaturedListingsInProgress: false,
  queryFeaturedListingsError: null,
};

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        queryListingsInProgress: false,
        queryListingsError: null,
        queryListingIds: payload.listingIds,
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, queryListingsInProgress: false, queryListingsError: payload };

    case QUERY_FEATURED_LISTINGS_REQUEST:
      return {
        ...state,
        queryFeaturedListingsInProgress: true,
        queryFeaturedListingsError: null,
      };
    case QUERY_FEATURED_LISTINGS_SUCCESS:
      return {
        ...state,
        queryFeaturedListingsInProgress: false,
        queryFeaturedListingsError: null,
        queryFeaturedListingIds: payload.listingIds,
      };
    case QUERY_FEATURED_LISTINGS_ERROR:
      return {
        ...state,
        queryFeaturedListingsInProgress: false,
        queryFeaturedListingsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryListingsRequest = params => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { params },
});

export const queryListingsSuccess = listingIds => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingIds },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryFeaturedListingsRequest = params => ({
  type: QUERY_FEATURED_LISTINGS_REQUEST,
  payload: { params },
});

export const queryFeaturedListingsSuccess = listingIds => ({
  type: QUERY_FEATURED_LISTINGS_SUCCESS,
  payload: { listingIds },
});

export const queryFeaturedListingsError = e => ({
  type: QUERY_FEATURED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryListings = params => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(params));

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  // First query with pub_featured: true
  return sdk.listings
    .query({
      include: ['author', 'images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      // Pick only the id property from the response listings
      const listingIds = response.data.data.map(({ id }) => id);
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingIds));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryFeaturedListings = params => (dispatch, getState, sdk) => {
  dispatch(queryFeaturedListingsRequest(params));

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  // First query with pub_featured: true
  return sdk.listings
    .query({
      pub_featured: true,
      include: ['author', 'images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      // Pick only the id property from the response listings
      const featuredListingIds = response.data.data.map(({ id }) => id);
      dispatch(addMarketplaceEntities(response));
      dispatch(queryFeaturedListingsSuccess(featuredListingIds));
      return response;
    })
    .catch(e => dispatch(queryFeaturedListingsError(storableError(e))));
};

export const loadData = (params, search) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  return Promise.all([
    dispatch(fetchPageAssets(pageAsset, true)),
    dispatch(queryListings()),
    dispatch(queryFeaturedListings()),
  ]);
};
