import React, { useEffect, useState } from 'react';
import { arrayOf, shape, string, func, object, bool } from 'prop-types';
import { NamedLink, IconArrowHead } from '../../../..';
import classNames from 'classnames';

// shared
import { isConflictingItems, VIEW_ALL_KEY } from '../TopbarDropdownMenu.helpers';
import DropdownSubItems from '../DropdownSubItems/DropdownSubItems';
import css from './Dropdown.module.css';

// Define constants for arrow position
const DROPDOWN_ITEM_ARROW_POSITION = 'right';

/**
 * Component that shows the single dropdown item.
 */
const DropdownListItem = props => {
  const { item, activeDropdownIndex, setActiveDropdownIndex, isBrand } = props;

  // Extract label and options from the item object
  const { label, options } = item;

  // Check if the current item has sub-items
  const hasOptions =
    options?.length > 0 && !(options.length === 1 && options.find(o => o.key === VIEW_ALL_KEY));

  // Check if the current item is conflicting
  const conflictingItems = isConflictingItems(item.key);
  const subCategoryOrSubCategories =
    item.subCategories?.length > 0 // if subcategories exist, consider them as main subcategories
      ? 'has_any:' + item.subCategories?.map(s => s.key).join(',') // Added 'has_any' before the joined string
      : item.key;

  // Define the redirect params
  const toSearch = isBrand
    ? `pub_brand=${item.key}`
    : conflictingItems
    ? `pub_category=${item.category}`
    : `pub_category=${item.category}&pub_subcategory=${subCategoryOrSubCategories}`;

  // Check if sub-items should be shown
  const showSubItems = activeDropdownIndex === item.key && hasOptions;

  // Create and show item arrow
  const itemArrow = hasOptions ? (
    <IconArrowHead className={css.dropdownListItemArrow} direction={DROPDOWN_ITEM_ARROW_POSITION} />
  ) : null;

  // Create the sub-items component
  const subItems = showSubItems ? <DropdownSubItems options={options} /> : null;
  const itemNumberingMaybe = isBrand ? '' : item.numbering + ' . '

  return (
    <li
      className={css.dropdownListItem}
      onMouseEnter={() => {
        setActiveDropdownIndex(item.key);
      }}
    >
      <NamedLink
        className={css.dropdownListItemLink}
        name="SearchPage"
        to={{
          search: toSearch,
        }}
      >
        <span className={css.itemBorder} />
        {itemNumberingMaybe}{label}
        {itemArrow}
      </NamedLink>
      {subItems}
    </li>
  );
};

DropdownListItem.defaultProps = {
  item: null,
  activeDropdownIndex: null,
  setActiveDropdownIndex: null,
  isBrand: false,
};

DropdownListItem.propTypes = {
  item: object,
  activeDropdownIndex: string,
  setActiveDropdownIndex: func.isRequired,
  isBrand: bool.isRequired,
};

const BrandDropdownList = props => {
  const { options, activeDropdownIndex, setActiveDropdownIndex } = props;

  const AGbrands = options.filter(i => i.type === 'A-G');
  const GMbrands = options.filter(i => i.type === 'G-M');
  const NRbrands = options.filter(i => i.type === 'N-R');
  const SZbrands = options.filter(i => i.type === 'S-Z');

  const brands = [AGbrands, GMbrands, NRbrands, SZbrands];
  return (
    <div className={css.dropdownGridList}>
      {brands.map((b, index) => {
        return (
          <ul key={index} className={css.dropdownList}>
            <li className={css.dropdownListItem}>
              <span className={css.dropdownListItemType}>{b[0]?.type || ''}</span>
            </li>
            {b.map(i => {
              return (
                <DropdownListItem
                  key={i.key}
                  item={i}
                  activeDropdownIndex={activeDropdownIndex}
                  setActiveDropdownIndex={setActiveDropdownIndex}
                  isBrand
                />
              );
            })}
          </ul>
        );
      })}
    </div>
  );
};

/**
 * Component that shows the dropdown list.
 */
const DropdownList = props => {
  const {
    option,
    options,
    activeDropdownIndex,
    setActiveDropdownIndex,
    setScrollBtnsAreHovered,
    scrollDrown,
    scrollUp,
  } = props;
  const isDogItem = option.key === 'dog';

  return (
    <ul className={css.dropdownList}>
      {scrollUp && (
        <li
          className={css.dropdownListItem}
          onMouseEnter={() => {
            setActiveDropdownIndex('up');
            setScrollBtnsAreHovered(true);
          }}
          onMouseLeave={() => {
            setScrollBtnsAreHovered(false);
          }}
        >
          <span
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              scrollUp();
            }}
            className={css.sortBtn}
          >
            <IconArrowHead className={css.dropdownListItemArrow} direction={'up'} />
          </span>
        </li>
      )}

      {scrollDrown && (
        <li
          className={css.dropdownListItem}
          onMouseEnter={() => {
            setActiveDropdownIndex('down');
            setScrollBtnsAreHovered(true);
          }}
          onMouseLeave={() => {
            setScrollBtnsAreHovered(false);
          }}
        >
          <span
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              scrollDrown();
            }}
            className={css.sortBtn}
          >
            <IconArrowHead className={css.dropdownListItemArrow} direction={'down'} />
          </span>
        </li>
      )}

      {options.map(i => {
        const isLittersItem = i.key === 'litters';
        if (isDogItem && isLittersItem) {
          return null;
        }
        return (
          <DropdownListItem
            key={i.key}
            item={i}
            activeDropdownIndex={activeDropdownIndex}
            setActiveDropdownIndex={setActiveDropdownIndex}
          />
        );
      })}
    </ul>
  );
};

DropdownList.defaultProps = {
  options: [],
  activeDropdownIndex: null,
  setActiveDropdownIndex: null,
};

DropdownList.propTypes = {
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  activeDropdownIndex: string,
  setActiveDropdownIndex: func.isRequired,
};

/**
 * Component that shows the dropdown.
 */
const Dropdown = props => {
  let { rootClassName, className, option, isBrand, options: originalOptions, ...rest } = props;
  originalOptions = originalOptions.map((ob, index) => ({ ...ob, numbering: index + 1 }));

  const classes = classNames(rootClassName || className, css.root, {
    [css.brandRoot]: isBrand,
  });

  // Define the active dropdown index
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);

  const ITEM_PER_VIEW = 7;
  const UPDATE_NUMBER_OF_ITEMS = 5;
  const [options, setOptions] = useState(originalOptions.slice(0, ITEM_PER_VIEW));

  const handleScrollDown = () => {
    // Calculate the last item's numbering
    const lastNumbering = options[options.length - 1].numbering;
    const nextLastNumbering = lastNumbering + UPDATE_NUMBER_OF_ITEMS;

    // Get the next items based on the last numbering
    const nextItems = originalOptions.slice(lastNumbering, nextLastNumbering);

    // If there are items to add, update the options
    if (nextItems.length > 0) {
      // Ensure we do not exceed ITEM_PER_VIEW
      const updatedOptions = [...options, ...nextItems].slice(-ITEM_PER_VIEW);
      setOptions(updatedOptions);
    }
  };

  const handleScrollUp = () => {
    // Get the current first item's numbering
    const firstNumbering = options[0].numbering;

    // Calculate the new items to prepend
    const newStartIndex = Math.max(0, firstNumbering - UPDATE_NUMBER_OF_ITEMS - 1);
    const newItems = originalOptions.slice(newStartIndex, firstNumbering - 1);

    // Prepend new items and ensure the view is limited to ITEM_PER_VIEW
    setOptions(prevOptions => [...newItems, ...prevOptions].slice(0, ITEM_PER_VIEW));
  };

  return (
    <div className={classes}>
      {isBrand ? (
        <BrandDropdownList
          activeDropdownIndex={activeDropdownIndex}
          setActiveDropdownIndex={setActiveDropdownIndex}
          options={originalOptions}
          {...rest}
        />
      ) : (
        <DropdownList
          option={option}
          activeDropdownIndex={activeDropdownIndex}
          setActiveDropdownIndex={setActiveDropdownIndex}
          options={options}
          scrollUp={options[0]?.numbering === originalOptions[0]?.numbering ? null : handleScrollUp}
          scrollDrown={
            options[options.length - 1]?.numbering ===
            originalOptions[originalOptions.length - 1]?.numbering
              ? null
              : handleScrollDown
          }
          {...rest}
        />
      )}
    </div>
  );
};

DropdownSubItems.defaultProps = {
  rootClassName: null,
  className: null,
  isBrand: false,
};

DropdownSubItems.propTypes = {
  rootClassName: string,
  className: string,
  option: object,
  isBrand: bool.isRequired,
};

export default Dropdown;
