import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';

import config from '../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  ModalInMobile,
  Button,
  AvatarSmall,
  SecondaryButton,
  InlineTextButton,
  PrimaryButton,
  NamedLink,
} from '../../components';
import { findConfigForSelectFilter } from '../../util/search';
import { types as sdkTypes } from '../../util/sdkLoader';

import BookingDatesForm from './BookingDatesForm/BookingDatesForm';
import ProductOrderForm from './ProductOrderForm/ProductOrderForm';
import css from './OrderPanel.module.css';
import IconLock from '../IconLock/IconLock';

const { Money } = sdkTypes;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    currentUser,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    author,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onAddToCart,
    addToCartInProgress,
    addToCartError,
    currentUserCart,
    shippingFee,
    onUpdateQuantity,
    onChangeColor,
    onChangeSize,
    filterConfig,
    transaction,
  } = props;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const shouldHaveBooking = isNightly || isDaily;

  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = shouldHaveBooking && hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = config.listingManagementType === 'stock' && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm =
    config.listingManagementType === 'stock' && typeof currentStock === 'number';

  // Check wheter the listing is already in
  // currentUser cart
  const isListingAlreadyInCart = currentUserCart
    ?.map(i => i.listingId)
    ?.includes(listing?.id?.uuid);
  const isAddToCartButtonDisabled = !!(
    isOwnListing ||
    isOutOfStock ||
    isListingAlreadyInCart ||
    addToCartInProgress ||
    addToCartError
  );

  const {
    pickupEnabled,
    shippingEnabled,
    color,
    customColor,
    sizes: sizesFromPublicData,
    minimumOrderQuantity,
  } = listing?.attributes?.publicData || {};

  const msrpPrice = listing?.attributes?.publicData?.msrpPrice;
  const showMsrpPrice = currentUser && msrpPrice;
  const msrpPriceMoney = showMsrpPrice && new Money(msrpPrice.amount, msrpPrice.currency);
  const priceToUse = listing.attributes.price;
  const price = new Money(priceToUse.amount, priceToUse.currency);
  const listingPrice = new Money(
    listing.attributes.price.amount,
    listing.attributes.price.currency
  );

  const minimumOrderPrice = minimumOrderQuantity
    ? new Money(price.amount * parseInt(minimumOrderQuantity), price.currency)
    : null;

  const customColorFromPublicData = customColor || [];
  const colorsFromPublicData = color || [];
  const colorConfig = findConfigForSelectFilter('color', filterConfig);
  const colors =
    colorConfig && colorConfig.options ? colorConfig.options.concat(customColorFromPublicData) : [];
  const colorOptions = colorsFromPublicData.map(c => colors.find(color => color.key === c));

  const sizeConfig = findConfigForSelectFilter('size', filterConfig);
  const sizes = sizeConfig && sizeConfig.options ? sizeConfig.options : [];
  const sizeOptions = sizesFromPublicData?.map(s => {
    const sizeOption = sizes.find(size => size.key === s.size);
    return {
      price: s.price,
      ...sizeOption,
    };
  });

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const enquireButton = onContactUser ? (
    <div className={css.enquireWrapper}>
      <InlineTextButton onClick={onClickContactUser}>
        <FormattedMessage id="ProductOrderForm.enquireButton" />
      </InlineTextButton>
    </div>
  ) : null;

  return (
    <div className={classes}>
      {/* <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      > */}
      <div className={css.modalHeading}>
        <h1 className={css.title}>{title}</h1>
      </div>

      <div className={css.orderHeading}>
        <h2 className={titleClasses}>{title}</h2>
        {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
      </div>

      <div className={css.priceContainer}>
        <p className={css.price}>{formatMoney(intl, listingPrice)}</p>
        {msrpPriceMoney ? (
          <p className={css.msrpPrice}>
            <FormattedMessage
              id="OrderPanel.msrpPrice"
              values={{ price: <span>{formatMoney(intl, msrpPriceMoney)}</span> }}
            />
          </p>
        ) : (
          <NamedLink
            className={css.ctaMsrpPrice}
            name="BuyerSignupPage"
            to={{
              state: {
                from: `${location.pathname}${location.search}${location.hash}`,
              },
            }}
          >
            <IconLock />
            <FormattedMessage id="OrderPanel.ctaMsrpPrice" />
          </NamedLink>
        )}
      </div>

      {minimumOrderPrice ? (
        <div className={css.minimumOrderPrice}>
          <FormattedMessage
            id="OrderPanel.minimumOrderPrice"
            values={{
              price: formatMoney(intl, minimumOrderPrice),
            }}
          />
        </div>
      ) : null}
      <div className={css.author}>
        <AvatarSmall user={author} className={css.providerAvatar} />
        <FormattedMessage id="OrderPanel.soldBy" values={{ name: authorDisplayName }} />
      </div>

      {showBookingDatesForm ? (
        <BookingDatesForm
          className={css.bookingForm}
          formId="OrderPanelBookingDatesForm"
          submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
          unitType={unitType}
          onSubmit={onSubmit}
          price={price}
          listingId={listing.id}
          isOwnListing={isOwnListing}
          timeSlots={timeSlots}
          fetchTimeSlotsError={fetchTimeSlotsError}
          onFetchTransactionLineItems={onFetchTransactionLineItems}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
        />
      ) : showProductOrderForm ? (
        <ProductOrderForm
          formId="OrderPanelProductOrderForm"
          location={location}
          onSubmit={onSubmit}
          price={price}
          currentUser={currentUser}
          currentStock={currentStock}
          pickupEnabled={pickupEnabled}
          shippingEnabled={shippingEnabled}
          colorOptions={colorOptions}
          sizeOptions={sizeOptions}
          minimumOrderQuantity={minimumOrderQuantity}
          listingId={listing.id}
          isOwnListing={isOwnListing}
          onFetchTransactionLineItems={onFetchTransactionLineItems}
          transaction={transaction}
          onContactUser={onContactUser}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
          onAddToCart={onAddToCart}
          addToCartInProgress={addToCartInProgress}
          addToCartError={addToCartError}
          isListingAlreadyInCart={isListingAlreadyInCart}
          isAddToCartButtonDisabled={isAddToCartButtonDisabled}
          shippingFee={shippingFee}
          initialValues={{
            deliveryMethod: 'shipping',
            color: colorsFromPublicData[0],
            quantity: minimumOrderQuantity ? parseInt(minimumOrderQuantity) : null,
          }}
          onUpdateQuantity={onUpdateQuantity}
          onChangeColor={onChangeColor}
          onChangeSize={onChangeSize}
          enquireButton={enquireButton}
        />
      ) : null}
      {/* </ModalInMobile> */}
      {/* <div className={css.openOrderForm}>
        <div className={css.buttonsWrapper}>
          <PrimaryButton
            className={css.addToCartButton}
            type="button"
            onClick={onAddToCart}
            inProgress={addToCartInProgress}
            disabled={isAddToCartButtonDisabled}
          >
            {isListingAlreadyInCart ? (
              <FormattedMessage id="ProductOrderForm.alreadyInCart" />
            ) : (
              <FormattedMessage id="ProductOrderForm.addToCart" />
            )}
          </PrimaryButton>
           {isClosed ? (
            <div className={css.closedListingButton}>
              <FormattedMessage id="OrderPanel.closedListingButtonText" />
            </div>
          ) : (
            <Button
              rootClassName={css.orderButton}
              onClick={() => openOrderModal(isOwnListing, isClosed, history, location)}
              disabled={isOutOfStock}
            >
              {isOutOfStock ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
              ) : (
                <FormattedMessage id="OrderPanel.ctaButtonMessage" />
              )}
            </Button>
          )}
        </div>
        {enquireButton}
      </div> */}
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.lineItemUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
  currentUserCart: [],
  filterConfig: config.custom.filters,

  // cart
  onAddToCart: null,
  addToCartInProgress: false,
  addToCartError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.lineItemUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  currentUserCart: array.isRequired,
  filterConfig: array.isRequired,

  // cart
  onAddToCart: func.isRequired,
  addToCartInProgress: bool.isRequired,
  addToCartError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
