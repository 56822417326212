import React from 'react';
import { arrayOf, shape, string, object } from 'prop-types';
import { NamedLink } from '../../../..';
import classNames from 'classnames';

// shared
import { isConflictingItems } from '../TopbarDropdownMenu.helpers';
import css from './DropdownSubItems.module.css';

/**
 * Component that shows the single dropdown sub item.
 */
const DropdownSubItem = props => {
  const { item } = props;
  const { label, category, collection, key: subcategory } = item;

  const conflictingSubItems = isConflictingItems(subcategory);

  const toSearch = conflictingSubItems
    ? `pub_category=${category}&pub_subcategory=${collection}`
    : `pub_category=${category}&pub_subcategory=${subcategory}`;

  return (
    <li className={css.dropdownSubItemsListItem}>
      <NamedLink
        className={css.dropdownSubItemsListItemLink}
        name="SearchPage"
        to={{ search: toSearch }}
      >
        {label}
        <span className={css.subItemBorder} />
      </NamedLink>
    </li>
  );
};

DropdownSubItem.defaultProps = {
  item: null,
};

DropdownSubItem.propTypes = {
  item: object,
};

/**
 * Component that shows the dropdown sub items list.
 */
const DropdownSubItemsList = props => {
  const { options } = props;
  return (
    <ul className={css.dropdownSubItemsList}>
      {options.map(o => {
        return <DropdownSubItem key={o.key} item={o} />;
      })}
    </ul>
  );
};

DropdownSubItemsList.defaultProps = {
  options: [],
};

DropdownSubItemsList.propTypes = {
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

/**
 * Component that shows the dropdown sub items.
 */
const DropdownSubItems = props => {
  const { rootClassName, className, scrolled = 0, ...rest } = props;
  const classes = classNames(rootClassName || className, css.root);
  return (
    <div className={classes} style={scrolled > 50 ? { top: `-${scrolled}px` } : {}}>
      <div className={css.subItemWrapper}>
        <DropdownSubItemsList {...rest} />
      </div>
    </div>
  );
};

DropdownSubItems.defaultProps = {
  rootClassName: null,
  className: null,
};

DropdownSubItems.propTypes = {
  rootClassName: string,
  className: string,
};

export default DropdownSubItems;
